import React, { useState } from "react";
import "./styles.css";

export const Select = (props) => {
  const { options, value, onChange } = props;
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export const CustomSelectItem = (props) => {
  return <></>;
};

export const CustomSelect = (props) => {
  const { value, onChange, children } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const items = React.Children.map(children, (child) => ({
    value: child.props.value,
    label: child.props.label,
  }));
  const selectedItem = items.filter((item) => item.value === value)[0];
  const label = selectedItem ? selectedItem.label : "None Selected";

  const togglePopover = () => {
    setPopoverOpen((open) => !open);
  };

  const handleSelect = (value) => {
    onChange(value);
    togglePopover();
  };

  return (
    <div className="select">
      <button className="select-button" onClick={togglePopover}>
        {label}
      </button>
      {popoverOpen && (
        <div className="select--dropdown">
          <ul className="select--dropdown-list">
            {items.map((item) => (
              <li className="select---dropdown-list-item" key={item.value}>
                <button
                  className="select--dropdown-button"
                  onClick={() => handleSelect(item.value)}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export const SelectPage = () => {
  const [value, setValue] = useState("js");
  return (
    <>
      <h1>Native Select</h1>
      <div>Selected Value: {value}</div>
      <Select
        options={[
          { label: "JavaScript", value: "js" },
          { label: "CSS", value: "css" },
        ]}
        value={value}
        onChange={setValue}
      />
      <div style={{ height: "1rem" }} />
      <CustomSelect value={value} onChange={setValue}>
        <CustomSelectItem label="JavaScript" value="js" />
        <CustomSelectItem label="CSS" value="css" />
      </CustomSelect>
    </>
  );
};
