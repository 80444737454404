import { useState } from "react";

const items = [];
for (let i = 0; i < 1000; i += 1) {
  items.push({ id: i, content: `${i} content` });
}

function VirtualizedList({ items, itemHeight, containerHeight }) {
  const [scrollTop, setScrollTop] = useState(0);
  // Use Math.floor to makes sure when we partially pass an element, we can still show part of the element.
  const startIndex = Math.floor(scrollTop / itemHeight);
  // Use Math.ceil to makes sure when the container is not a exact multiple of itemHeight, still show part of last visible element.
  const endIndex = Math.min(
    startIndex + Math.ceil(containerHeight / itemHeight),
    items.length
  );
  const visibleItems = items.slice(startIndex, endIndex);

  const handleScroll = (event) => {
    setScrollTop(event.target.scrollTop);
  };

  return (
    <div
      style={{ height: `${containerHeight}px`, overflowY: "scroll" }}
      onScroll={handleScroll}
    >
      <div style={{ height: `${items.length * itemHeight}px` }}>
        <div
          style={{
            position: "relative",
            height: `${visibleItems.length * itemHeight}px`,
            top: `${startIndex * itemHeight}px`,
          }}
        >
          {visibleItems.map((item) => (
            <div key={item.id} style={{ height: `${itemHeight}px` }}>
              {item.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export const VirutalRendering = () => {
  return (
    <>
      <VirtualizedList items={items} itemHeight={20} containerHeight={210} />
      <h1>General Idea</h1>
      <div>
        First div is set to container height and we can scroll inside the it.
      </div>
      <div>
        Second div is set to height of all items and it's child of the first
        div. So we can see correct scrollbar in first div.
      </div>
      <div>
        We need a third div to render actual items. It uses top value to makes
        it visible in first div. The height of it is the height of visible
        items.
      </div>
      <div>
        When user scrolls in the first div, we record the scroll top. The scroll
        top will be used to calculate the start index of visible items. The
        start index is used to generate the top value of third div.
      </div>
    </>
  );
};
