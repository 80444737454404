import { useState } from "react";
import "./style.css";

export const Tabs = (props) => {
  const { children, value, onChange } = props;
  if (children.length === 0) {
    return [];
  }
  const handleChangeTab = (tabName) => {
    onChange(tabName);
  };
  return (
    <>
      <ul className="tab-nav">
        {children.map((item) => (
          <li key={item.props.name}>
            <button
              className="tab-button"
              onClick={() => handleChangeTab(item.props.name)}
            >
              {item.props.label}
            </button>
          </li>
        ))}
      </ul>
      {children.map((item) => {
        if (item.props.name === value) {
          return item;
        }
        return null;
      })}
    </>
  );
};

export const Tab = (props) => {
  const { children } = props;
  return <div>{children}</div>;
};

export const TabPage = () => {
  const [tabValue, setTabValue] = useState("js");
  return (
    <>
      <Tabs value={tabValue} onChange={setTabValue}>
        <Tab label="JavaScript" name="js">
          Programming Language
        </Tab>
        <Tab label="CSS" name="css">
          Styling Lanuage
        </Tab>
        <Tab label="HTML" name="html">
          Markup Lanuage
        </Tab>
      </Tabs>
    </>
  );
};
