import { createRoot } from "react-dom/client";
import App from "./components/App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { StrictMode } from "react";
import Root from "./routes/root";
import ErrorPage from "./routes/error-page";
import { VirutalRendering } from "./routes/virtual-rendering";
import { TabPage } from "./routes/tab";
import { Home } from "./routes/home";
import { SelectPage } from "./routes/select";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "virtual-rendering",
        element: <VirutalRendering />,
      },
      {
        path: "tabs",
        element: <TabPage />,
      },
      {
        path: "select",
        element: <SelectPage />,
      },
      {
        path: "",
        element: <Home />,
      },
    ],
  },
]);

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
