import { Link } from "react-router-dom";
import "./styles.css";

const pages = [
  { label: "Tabs", path: "tabs" },
  { label: "Virtual Rendering", path: "virtual-rendering" },
  { label: "Dropdown", path: "select" },
];
export const Home = () => {
  return (
    <ul>
      {pages.map((page) => (
        <li key={page.path}>
          <Link className="home--link" to={page.path}>
            {page.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};
